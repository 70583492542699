import { all } from 'redux-saga/effects';

import appSagas from './app-sagas';
import apiSagas from './api-sagas';

/**
 * CORE
 */

export default function* () {
  yield all([
    ...appSagas,
    ...apiSagas,
  ]);
}
