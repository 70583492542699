import React from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar,
} from 'reactstrap';

import Logo from '../assets/logo-nav.png';

const Header = () => (
  <Navbar
    color="dark"
    dark
    className="justify-content-center"
  >
    <Link
      to="/code"
      className="navbar-brand"
    >
      <img
        className="img-fluid logo-nav"
        src={Logo}
        alt="Raiders' logo"
      />
    </Link>
  </Navbar>
);

export default Header;
