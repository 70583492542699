export const setCode = (code) => (
  {
    type: 'SET_CODE',
    code: code.toUpperCase(),
  }
);

export const resetError = () => (
  {
    type: 'RESET_ERROR',
  }
);
