import React from 'react';
import PropTypes from 'prop-types';

import noImage from '../../assets/noImg.png';

const onImgError = (e) => {
  e.target.onerror = null;
  e.target.src = noImage;
  return true;
};

const ImageItem = ({
  screenHeight,
  preview,
  url,
  label,
}) => (
  <img
    className="image-item"
    data-src={preview || url}
    alt={label}
    onError={onImgError}
    style={{
      maxHeight: `calc(${screenHeight}px - 3rem)`,
    }}
  />
);

ImageItem.propTypes = {
  screenHeight: PropTypes.number.isRequired,
  preview: PropTypes.string,
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
};

ImageItem.defaultProps = {
  preview: null,
  label: null,
};

export default ImageItem;
