/* global window */
export const trackEvent = (type, values = {}) => {
  window.dataLayer.push({
    eventData: values,
    event: type,
  });
};

export const setValues = (values = {}) => {
  window.dataLayer.push(values);
};
