/* global window, document */
import React, { Component } from 'react';
import FullPage from '@fullpage/react-fullpage';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faCircleNotch,
  faArrowDown,
  faFilm,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import ReactInterval from 'react-interval';
import {
  Button,
} from 'reactstrap';
import { Translate } from 'react-i18nify';
import { parse as parseUrl } from 'url';

import Header from './Header';
import GalleryItem from './GalleryItem';

import OvenImage from '../assets/oven.png';

import {
  getMedias as getMediasSelector,
  getInitialized as getInitializedSelector,
  isMediasFetched as isMediaFetchedSelector,
  shouldFetchMedias as shouldFetchMediasSelector,
  getSuccessStatus,
} from '../selectors';

import {
  getMedias,
} from '../actions/api-actions';

import { setValues } from '../utils/GoogleAnalytics';

const {
  REACT_APP_PROJECT_PREFIX: PROJECT_PREFIX,
  REACT_APP_NAVIGATION_THRESHOLD: NAVIGATION_THRESHOLD = 20,
} = process.env;

const Navigation = ({ enabled, count, index }) => (
  <div className={`fp-alt-nav ${enabled ? 'active' : ''}`}>
    <span>
      {`${index}/${count}`}
    </span>
  </div>
);

Navigation.propTypes = {
  enabled: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const hideSlideAnim = () => {
  document.getElementById('slide-down-anim-container').classList.add('hide');
};

const SlideDownAnim = () => (
  <div
    id="slide-down-anim-container"
    className="slide-down-anim-container"
  >
    <ReactInterval
      timeout={3000}
      callback={hideSlideAnim}
      enabled
      repeat={false}
    />
    <span>
      <Translate value="gallery.slide_down" />
    </span>
    <span>
      <FA icon={faArrowDown} className="slide-down-anim-icon" />
    </span>
  </div>
);

const InitializedPage = ({ initialized, onRefreshClick }) => (
  <div className="section">
    <div className="initialized-container">
      <img
        src={OvenImage}
        alt="initialized medias illustration"
        className="initialized-icon"
      />
      <span className="title">
        <Translate value="gallery.more_content_soon" />
      </span>
      <span>
        <FA icon={faFilm} />
        &nbsp;
        {initialized.filter(({ type }) => type === 'VIDEO').length}
        &nbsp;
        &nbsp;
        <FA icon={faImage} />
        &nbsp;
        {initialized.filter(({ type }) => type === 'PHOTO').length}
      </span>
      <span>
        <Button
          color="link"
          onClick={onRefreshClick}
        >
          <span>
            <Translate value="gallery.refresh" />
          </span>
        </Button>
      </span>
    </div>
  </div>
);

InitializedPage.propTypes = {
  initialized: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRefreshClick: PropTypes.func.isRequired,
};

const LoadingMediaMessage = () => (
  <div className="loading-container">
    <span className="loading-icon">
      <FA icon={faCircleNotch} fixedWidth spin />
    </span>
    <span className="title">
      <Translate value="gallery.loading" />
    </span>
  </div>
);

const NoMediasMessage = ({ onRefreshClick }) => (
  <div className="no-medias-container">
    <p className="no-medias-content title">
      <span style={{ fontSize: '2.5rem' }}>
        <Translate value="gallery.no_content.message" />
      </span>
      <span>
        <Translate value="gallery.no_content.wait" />
      </span>
      <span>
        <Translate value="gallery.no_content.hit_refresh" />
      </span>
      <span>
        <Translate value="gallery.no_content.not_close" />
      </span>
    </p>
    <Button
      color="primary"
      onClick={onRefreshClick}
      size="lg"
      style={{ marginTop: '2rem', marginBottom: '2rem' }}
    >
      <span className="uppercase">
        <Translate value="gallery.refresh" />
      </span>
    </Button>
    <span>
      <Link to="/code">
        <Translate value="gallery.no_content.back_home" />
      </Link>
    </span>
  </div>
);

NoMediasMessage.propTypes = {
  onRefreshClick: PropTypes.func.isRequired,
};

const getFileNameFromUrl = (url) => {
  const { pathname } = parseUrl(url);
  return pathname.split('/').pop();
}

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenHeight: window.innerHeight,
      currentSection: 1,
    };

    this.onSectionLeave = this.onSectionLeave.bind(this);

    // fullpage bug when updating its content -> refresh all page
    this.onRefresh = window.location.reload.bind(window.location);
  }

  componentDidMount() {
    window.addEventListener('resize', (e) => {
      this.setState({ screenHeight: e.target.innerHeight });
    });

    setValues({ projectId: PROJECT_PREFIX });
  }

  componentDidUpdate() {
    if (this.props.shouldFetchMedias) { // Medias not already fetched
      this.props.getMedias();
    }

    if (this.props.mediasFetched && !this.props.fetchSucceed) { // Medias fetched but invalid code
      this.props.history.push('/code');
    }

    if (this.props.initialized
      && this.props.initialized.length
      && document.getElementById('fp-nav')) { // Initialized content present
      document.getElementById('fp-nav').classList.add('initialized-medias');
    }
  }

  onSectionLeave(origin, { index }) {
    hideSlideAnim();
    this.setState({ currentSection: index + 1 });
  }

  render() {
    const {
      screenHeight,
      currentSection,
    } = this.state;
    const {
      medias,
      initialized,
      mediasFetched,
    } = this.props;

    const isLoading = !(medias.length || initialized.length) && !mediasFetched;
    const noMedias = !(medias.length || initialized.length) && mediasFetched;
    const mediasCount = medias.length + initialized.length;

    if (isLoading) {
      return <LoadingMediaMessage />;
    }

    if (noMedias) {
      return <NoMediasMessage onRefreshClick={this.onRefresh} />;
    }

    return (
      <>
        <Header />
        {mediasCount > 1 && (
          <SlideDownAnim />
        )}
        <Navigation
          enabled={mediasCount >= NAVIGATION_THRESHOLD}
          count={mediasCount}
          index={currentSection}
        />
        <FullPage
          licenseKey="051817C6-5ADB4296-AD068671-B343C41B"
          scrollingSpeed={1000}
          navigation={mediasCount < NAVIGATION_THRESHOLD} // default navigation under threshold
          paddingTop="3rem" // same as header
          onLeave={this.onSectionLeave}
          render={() => (
            <FullPage.Wrapper>
              {medias.map((media) => (
                <GalleryItem
                  {...media}
                  screenHeight={screenHeight}
                  key={getFileNameFromUrl(media.url)}
                />
              ))}
              {initialized.length && (
                <InitializedPage
                  initialized={initialized}
                  onRefreshClick={this.onRefresh}
                />
              )}
            </FullPage.Wrapper>
          )}
        />
      </>
    );
  }
}

Gallery.propTypes = {
  medias: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialized: PropTypes.arrayOf(PropTypes.object).isRequired,
  shouldFetchMedias: PropTypes.bool.isRequired,
  mediasFetched: PropTypes.bool.isRequired,
  fetchSucceed: PropTypes.bool.isRequired,
  // react-router-dom
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // actions
  getMedias: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    medias: getMediasSelector(state),
    initialized: getInitializedSelector(state),
    mediasFetched: isMediaFetchedSelector(state),
    shouldFetchMedias: shouldFetchMediasSelector(state),
    fetchSucceed: getSuccessStatus(state),
  }),
  {
    getMedias,
  },
)(Gallery);
