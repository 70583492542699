import React from 'react';
import PropTypes from 'prop-types';

import noVideo from '../../assets/noVideo.mp4';

const onVideoError = (e) => {
  e.target.onerror = null;
  e.target.src = noVideo;
  return true;
};

const VideoItem = ({
  screenHeight,
  cover,
  url,
}) => (
  <video
    className="video-item"
    data-src={url}
    onError={onVideoError}
    controls
    poster={cover}
    autoPlay
    loop
    muted
    data-autoplay
    playsInline
    style={{
      maxHeight: `calc(${screenHeight}px - 3rem)`,
    }}
  />
);

VideoItem.propTypes = {
  screenHeight: PropTypes.number.isRequired,
  cover: PropTypes.string,
  url: PropTypes.string.isRequired,
};
VideoItem.defaultProps = {
  cover: null,
};

export default VideoItem;
