const initial = {
  medias: [],
  initialized: [],
  fetching: false,
  fetched: false,
  success: false,
  error: null,
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'GET_MEDIAS':
      return {
        ...state,
        fetching: true,
        fetched: false,
        success: false,
        error: null,
      };

    case 'GET_MEDIAS_SUCCESS':
      return {
        ...state,
        medias: action.medias
          .filter(({ status }) => status === 'AVAILABLE'),
        initialized: action.medias
          .filter(({ status }) => status === 'INITIALIZED'),
        fetching: false,
        fetched: true,
        success: true,
        error: null,
      };

    case 'GET_MEDIAS_FAILURE':
      return {
        ...state,
        fetching: false,
        fetched: true,
        success: false,
        error: action.error,
      };

    case 'RESET_ERROR':
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
