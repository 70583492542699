/* global document */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { setTranslations, setLocale } from 'react-i18nify';

import { getTranslations, getPreferedLocale } from './utils/translations';
import App from './components/App';
import reducer from './reducers';
import rootSaga from './sagas';
import * as serviceWorker from './serviceWorker';

import './css/index.scss';

const {
  NODE_ENV,
} = process.env;

const isProd = NODE_ENV === 'production';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = createStore(
  reducer,
  isProd
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(rootSaga);

setTranslations(getTranslations());
setLocale(getPreferedLocale() || 'en');

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route component={App} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
