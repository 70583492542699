import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

import { VideoItem, ImageItem } from './mediaItems';

import { trackEvent } from '../utils/GoogleAnalytics';

const GalleryItemFooter = ({ label, download, productId }) => (
  <div className="gallery-item-footer">
    <Container>
      <Row>
        {label && (
          <Col>
            <span className="gallery-item-name">{label}</span>
          </Col>
        )}
        {download && (
          <Col align="right">
            <a href={download}>
              <Button
                color="secondary"
                outline
                className="download-button"
                type="submit"
                onClick={() => trackEvent('download', { productId })}
              >
                <FA icon={faCloudDownloadAlt} fixedWidth />
              </Button>
            </a>
          </Col>
        )}
      </Row>
    </Container>
  </div>
);

GalleryItemFooter.propTypes = {
  label: PropTypes.string,
  download: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

GalleryItemFooter.defaultProps = {
  label: null,
};

const GalleryItem = ({
  type,
  label,
  download,
  productId,
  ...props
}) => (
  <div className="gallery-item section">
    <Container>
      <Card>
        <CardBody>
          <div className="gallery-item-content">
            {type === 'VIDEO' && (
              <VideoItem {...props} />
            )}
            {type === 'PHOTO' && (
              <ImageItem label={label} {...props} />
            )}
          </div>
          <GalleryItemFooter
            label={label}
            download={download}
            productId={productId}
          />
        </CardBody>
      </Card>
    </Container>
  </div>
);

GalleryItem.propTypes = {
  type: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  download: PropTypes.string.isRequired,
  label: PropTypes.string,
};

GalleryItem.defaultProps = {
  label: null,
};

export default GalleryItem;
