import {
  takeEvery,
  put,
} from 'redux-saga/effects';

function* setCode({ code }) {
  yield put({ type: 'SET_CODE_SUCCESS', code });
}

export default [
  takeEvery('SET_CODE', setCode),
];
