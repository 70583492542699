import { createSelector } from 'reselect';

/**
 * APP SELECTORS
 */

export const getCode = ({ app: { code } }) => code;

/**
 * MEDIA SELECTORS
 */

export const getMedias = ({ media: { medias } }) => medias;

export const getError = ({ media: { error } }) => error;

export const getSuccessStatus = ({ media: { success } }) => success;

export const getInitialized = ({ media: { initialized } }) => initialized;

export const isMediasFetched = ({ media: { fetched } }) => fetched;

export const isMediasFetching = ({ media: { fetching } }) => fetching;

export const shouldFetchMedias = createSelector(
  getCode,
  isMediasFetched,
  isMediasFetching,
  (code, fetched, fetching) => (code && !fetched && !fetching) || false,
);
