/* global navigator */
/**
 * IMPORTS
 */

const _ = require('lodash');

const en = require('../assets/texts/en.json');
const fr = require('../assets/texts/fr.json');
const ro = require('../assets/texts/ro.json');

/**
* CONSTANTS
*/

const LANGUAGES = JSON.parse(process.env.REACT_APP_LANGUAGES) || ['en'];

/**
* CORE
*/

export const getTranslations = () => {
  const translations = { en, fr, ro };
  return _.pickBy(translations, (v, key) => LANGUAGES.includes(key));
};

export const getPreferedLocale = () => (
  _.intersection(
    navigator.languages.filter((l) => /\w/.test(l)),
    LANGUAGES,
  )[0]
);
