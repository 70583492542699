import {
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects';
import { v4 as uuidV4 } from 'uuid';
import axios from 'axios';

import {
  getCode,
} from '../selectors';

const {
  REACT_APP_PROJECT_PREFIX,
  REACT_APP_API_URL,
} = process.env;

function* callApi(url, args) {
  const apiRequestId = uuidV4();
  const context = { apiRequestId, url, args };
  yield put({ type: 'API_CALL_REQUEST', apiRequestId });
  try {
    const { data } = yield call(axios.get, `${REACT_APP_API_URL}${url}`, { params: args });
    yield put({ type: 'API_CALL_SUCCESS', data, ...context });
    return data;
  } catch (error) {
    if (error.response) {
      const { response: { data } } = error;
      yield put({ type: 'API_CALL_SUCCESS', data, ...context });
      return data;
    }
    yield put({ type: 'API_CALL_FAILURE', error, ...context });
  }
}

function* getMedias() {
  try {
    const mediaCode = yield select(getCode);
    const { success, data: medias, code, message } = yield callApi(`content/by-code/${REACT_APP_PROJECT_PREFIX}/${mediaCode}`);
    if (success) {
      yield put({
        type: 'GET_MEDIAS_SUCCESS',
        medias: medias
          .map(({ status, type, ...props }) => (
            { status: status.toUpperCase(), type: type.toUpperCase(), ...props }
          )),
      });
    } else {
      yield put({ type: 'GET_MEDIAS_FAILURE', error: { code, message } });
    }
  } catch (error) {
    yield put({ type: 'GET_MEDIAS_FAILURE', error });
  }
}

export default [
  takeEvery('GET_MEDIAS', getMedias),
];
