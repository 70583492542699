import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Gallery from './Gallery';
import Form from './Form';

import {
  setCode,
} from '../actions/app-actions';

const RedirectRoute = ({ location }) => (
  <Redirect
    to={{
      pathname: '/code',
      state: { from: location },
    }}
  />
);

RedirectRoute.propTypes = {
  location: PropTypes.string.isRequired,
};

class App extends Component {
  componentDidMount() {
    const { location: { search } } = this.props;
    const code = new URLSearchParams(search).get('code');
    if (!code) {
      this.props.history.push('/code');
    } else {
      this.props.setCode(code);
    }
  }

  render() {
    return (
      <div className="app">
        <Switch>
          <Route path="/code" exact component={Form} />
          <Route path="/" exact component={Gallery} />
          <Route path="*" component={RedirectRoute} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  // react-router-dom
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // actions
  setCode: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  {
    setCode,
  },
)(App);
