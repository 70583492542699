import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormFeedback,
  Input,
  InputGroup,
} from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { Translate, translate } from 'react-i18nify';

import {
  setCode,
  resetError,
} from '../actions/app-actions';

import {
  getMedias,
} from '../actions/api-actions';

import {
  getError,
  getSuccessStatus,
  getCode,
  isMediasFetching,
} from '../selectors';

import Logo from '../assets/logo.png';

const onFocus = (e) => e.target.select();

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: props.code,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { success, code } = this.props;
    if (success && !prevProps.success) {
      this.props.history.push(`/?code=${code}`);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let { code } = this.state;
    code = code.trim().toUpperCase();
    this.props.setCode(code);
    this.props.getMedias();
  }

  onChange(e) {
    const { error } = this.props;
    const { target: { name, value } } = e;

    if (error) {
      this.props.resetError();
    }
    this.setState({ [name]: value });
  }

  render() {
    const { error, fetching } = this.props;
    const { code } = this.state;

    if (fetching) {
      return (
        <div className="loading-container">
          <div className="form-background" />
          <span className="loading-icon">
            <FA icon={faCircleNotch} fixedWidth spin />
          </span>
        </div>
      );
    }

    return (
      <div className="form-container">
        <div className="form-background" />
        <BootstrapForm
          onSubmit={this.onSubmit}
          className="form"
          autoComplete="off"
        >
          <FormGroup>
            <img
              className="img-fluid logo"
              src={Logo}
              alt="Raiders' logo"
            />
            <InputGroup>
              <Input
                name="code"
                placeholder={translate('form.placeholder')}
                type="text"
                onChange={this.onChange}
                value={code}
                bsSize="lg"
                autoFocus
                onFocus={onFocus}
              />
            </InputGroup>
            {error && (
              <FormFeedback className="d-block">
                {error.code === 400 ? translate('form.invalid') : error.message}
              </FormFeedback>
            )}
          </FormGroup>
          <Button
            className={code.length ? '' : 'hide'}
            color="primary"
            type="submit"
            disabled={fetching || !!error}
            size="lg"
          >
            <span className="uppercase">
              <Translate value="form.validate" />
            </span>
          </Button>
        </BootstrapForm>
      </div>
    );
  }
}

Form.propTypes = {
  success: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  }),
  code: PropTypes.string,
  fetching: PropTypes.bool.isRequired,
  // react-router-dom
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // actions
  setCode: PropTypes.func.isRequired,
  getMedias: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
};

Form.defaultProps = {
  error: null,
  code: '',
};

export default connect(
  (state) => ({
    error: getError(state),
    success: getSuccessStatus(state),
    code: getCode(state),
    fetching: isMediasFetching(state),
  }),
  {
    setCode,
    getMedias,
    resetError,
  },
)(Form);
